var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v("Werkstromen")
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "elementPanel" }, [
                          _c("div", { staticClass: "chartContainer" }, [
                            _c("div", { staticClass: "overlay" }),
                            _c("div", { ref: "regulier" })
                          ])
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "elementPanel" }, [
                          _c("div", { staticClass: "chartContainer" }, [
                            _c("div", { staticClass: "overlay" }),
                            _c("div", { ref: "herzien" })
                          ])
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "elementPanel" }, [
                          _c("div", { staticClass: "chartContainer" }, [
                            _c("div", { staticClass: "overlay" }),
                            _c("div", { ref: "bezwaar" })
                          ])
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "elementPanel" }, [
                          _c("div", { staticClass: "chartContainer" }, [
                            _c("div", { staticClass: "overlay" }),
                            _c("div", { ref: "stuwmeer" })
                          ])
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "elementPanel" }, [
                          _c("div", { staticClass: "chartContainer" }, [
                            _c("div", { staticClass: "overlay" }),
                            _c("div", { ref: "ves" })
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }