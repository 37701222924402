import { Component, Vue, Watch } from 'vue-property-decorator';
import { getStatusLabel, getStatusColor, statusHexColor, ReportStatus } from '@/support/ReportStatus';
import Highcharts, { SVGPathArray } from 'highcharts';

@Component<Overview>({})
export default class Overview extends Vue {
  public $pageTitle = 'Werkstromen';

  protected isLoading = true;

  protected chart: any;

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
    this.initReguliereAdviesRapporten();
    this.initHerzienAdviesRapporten();
    this.initBezwaarAdviesRapporten();
    this.initSmrRapporten();
    this.initVesRapporten();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Werkstromen' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  protected initReguliereAdviesRapporten() {
    const chartContainer = (this.$refs.regulier as any);
    if (! chartContainer) {
      return;
    }

    const router = this.$router;

    const store = this.$store;

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        backgroundColor: 'white',
        height: 500,
        events: {
          load() {
            // Draw the flow chart
            const ren = this.renderer;
            const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, - 5];
            const leftArrow = ['M', 100, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, - 5];

            const drawHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, lineType, length, 0, lineType, arrowHook, 5, 'M', length, 0, lineType, arrowHook, - 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawReverseHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);
              ren.path(['M', 0, 0, lineType, length, 0, lineType, 0, 0, 'M', 0, 0, lineType, 5, - 5, lineType, 0, 0, lineType, 5, 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawVerticalArrow = function (posX: number, posY: number, length: number, color = 'grey') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, 'L', 0, length, 'L', 5, arrowHook, 'M', 0, length, 'L', - 5, arrowHook])
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawStatusBox = function (posX: number, posY: number, status: string, boxWidth = 100) {
              let label = getStatusLabel(status);

              if (status === 'veldwerk_rejected') label = 'Teruggezet <br> Schadebepaling';

              if (status === 'opname_werkvoorbereiding_done') label = 'Opname afgerond<br/>voor IMG';

              if (status === 'werkvoorbereiding_rejected') label = 'Teruggezet naar <br> Werkvoorbereiding';

              ren.label(label, posX, posY).attr({
                fill: statusHexColor[getStatusColor(status)],
                'stroke-width': 2,
                padding: 5,
                r: 5,
                width: boxWidth,
              }).css({
                color: 'white',
                cursor: 'pointer',
              }).add()
                .on('click', () => {
                  router.push(`/reports?status=${status}`);
                });
            };

            if (! rightArrow || ! leftArrow) return;

            // Separator, IMG | Opnemer
            ren.path(['M', 180, 40, 'L', 180, 450]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Opnamer | Bureau
            ren.path(['M', 380, 40, 'L', 380, 450]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Bureau | Schadebepaling & Afhandeling
            ren.path(['M', 780, 40, 'L', 780, 450]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Separator, Schadebepaling & Afhandeling | Juridische Zaken
            ren.path(['M', 1080, 40, 'L', 1080, 450]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Separator, Juridische Zaken | Overige
            ren.path(['M', 1380, 40, 'L', 1380, 450]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Headers
            ren.label('IMG', 20, 40).add();
            ren.label('(Externe) opnemer', 200, 40).add();
            ren.label('Bureau', 400, 40).add();
            ren.label('Schadebepaling', 800, 40).add();
            ren.label('Juridische Zaken', 1100, 40).add();
            ren.label('Overige', 1400, 40).add();

            // Werkvoorbereiding:
            drawStatusBox(10, 100, 'created', 140);
            drawVerticalArrow(75, 135, 20); // created --> planning
            drawHorizontalArrow(175, 113, 215); // created --> planning_organization

            // Planning
            drawStatusBox(10, 160, 'planning', 140);
            drawHorizontalArrow(165, 172, 30); // Planning --> In Opname

            // Teruggezet naar Werkvoorbereiding
            drawStatusBox(10, 220, 'opname_werkvoorbereiding_done', 140);

            // Teruggezet planning IMS
            drawStatusBox(10, 350, 'back_to_planning_ims', 150);

            // Teruggezet naar Werkvoorbereiding
            drawStatusBox(10, 280, 'werkvoorbereiding_rejected', 150);

            // In Opname
            drawStatusBox(200, 160, 'opname_in_progress');
            drawHorizontalArrow(320, 172, 70); // opname_in_progress --> opname_done

            // Opname afgekeurd
            drawStatusBox(200, 220, 'opname_rejected', 150);
            drawHorizontalArrow(365, 235, 30); // opname_rejected --> opname_done

            // Planning  Expertisebureau
            drawStatusBox(400, 100, 'planning_organization', 220);
            ren.path([
              'M', 390, 125,
              'L', 320, 160,
              'L', 322, 155,
              'M', 320, 160,
              'L', 326, 162,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // planning_organization --> opname_in_progress
            ren.path([
              'M', 650, 110,
              'C', 650, 110, 700, 160, 520, 220,
              'M', 520, 220,
              'L', 530, 222,
              'M', 520, 220,
              'L', 527, 211,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // planning_organization --> in_progress

            // Opname afgerond
            drawStatusBox(400, 160, 'opname_done', 140);
            drawVerticalArrow(440, 200, 10); // opname_done --> in_progress

            // Lopend
            drawStatusBox(400, 220, 'in_progress');
            drawHorizontalArrow(520, 232, 110); // in_progress --> submitted
            ren.path([
              'M', 396, 245,
              'C', 396, 240, 396, 260, 365, 245,
              'M', 365, 245,
              'L', 375, 243,
              'M', 365, 245,
              'L', 372, 253,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // veldwerk_corrected --> veldwerk_rejected

            // Afgekeurd
            drawStatusBox(400, 280, 'rejected');
            ren.path([
              'M', 640, 255,
              'C', 640, 255, 605, 305, 520, 300,
              'M', 640, 255,
              'L', 630, 258,
              'M', 640, 255,
              'L', 641, 263,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // rejected --> submitted

            // Supervisor
            drawStatusBox(640, 170, 'supervisor');
            ren.path([
              'M', 635, 185,
              'C', 635, 185, 635, 185, 520, 220,
              'M', 635, 185,
              'L', 625, 195,
              'M', 635, 185,
              'L', 625, 180,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // supervisor --> in_progress

            drawVerticalArrow(685, 197 + 17, - 17); // submitted --> supervisor
            drawVerticalArrow(705, 197, 17); // supervisor --> submitted

            // Voorcontrole
            drawStatusBox(640, 220, 'submitted');
            drawVerticalArrow(695, 252, 20); // submitted --> checking
            ren.path([
              'M', 630, 240,
              'C', 630, 240, 570, 240, 520, 285,
              'M', 520, 285,
              'L', 522, 275,
              'M', 520, 285,
              'L', 532, 283,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // submitted --> rejected

            // Te Controleren
            drawStatusBox(640, 280, 'checking');
            drawHorizontalArrow(755, 290, 55); // checking --> nieuw opgeleverd
            ren.path([
              'M', 755, 290,
              'L', 810, 340,
              'M', 810, 340,
              'L', 800, 339,
              'M', 810, 340,
              'L', 807, 330,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // checking --> rejected
            ren.path([
              'M', 630, 300,
              'C', 630, 300, 575, 330, 520, 315,
              'M', 520, 315,
              'L', 527, 324,
              'M', 520, 315,
              'L', 532, 311,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // checking --> rejected

            // Afgekeurd schadebepaling
            drawStatusBox(590, 340, 'veldwerk_rejected', 150);
            drawHorizontalArrow(755, 355, 55); // veldwerk_rejected --> veldwerk_corrected
            ren.path([
              'M', 580, 350,
              'C', 580, 350, 450, 350, 450, 320,
              'M', 450, 320,
              'L', 445, 329,
              'M', 450, 320,
              'L', 460, 325,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // checking --> rejected

            drawStatusBox(590, 415, ReportStatus.STRUCTURAL_ENGINEER_REJECTED, 150);
            drawVerticalArrow(650, 410, - 17, 'red');

            drawStatusBox(820, 220, ReportStatus.STRUCTURAL_ENGINEER_CHECKING, 150);
            drawVerticalArrow(895, 250, 20);

            // Nieuw Opgeleverd
            drawStatusBox(820, 280, 'approved', 150);
            ren.path([
              'M', 1000, 300,
              'C', 1000, 300, 1050, 350, 1000, 400,
              'M', 1000, 400,
              'L', 1002, 390,
              'M', 1000, 400,
              'L', 1010, 396,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // approved --> downloaded
            ren.path([
              'M', 810, 300,
              'C', 810, 300, 785, 325, 755, 345,
              'M', 755, 345,
              'L', 757, 335,
              'M', 755, 345,
              'L', 765, 345,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // approved --> Afgekeurd schadebepaling

            // Gecorrigeerd
            drawStatusBox(820, 340, 'veldwerk_corrected', 150);
            drawVerticalArrow(895, 372, 20); // veldwerk_corrected --> downloaded
            ren.path([
              'M', 810, 370,
              'C', 810, 370, 790, 390, 760, 370,
              'M', 764, 380,
              'L', 760, 370,
              'M', 772, 370,
              'L', 760, 370,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // veldwerk_corrected --> veldwerk_rejected

            // Verstuurd aan aanvrager
            drawStatusBox(820, 400, 'downloaded', 150);

            // Controle JZ
            drawStatusBox(1120, 100, 'specials_in_review', 150);

            // specials_in_review --> specials_reviewed
            drawReverseHorizontalArrow(995, 115, 100);

            // JZ beoordeeld
            drawStatusBox(820, 100, 'specials_reviewed', 150);

            // Probleem IMG
            drawStatusBox(1420, 100, 'problem_tcmg', 150);

            // Afgebroken opdrachten
            drawStatusBox(1420, 170, 'aborted', 150);
          },
        },
      },
      title: {
        text: 'Regulier',
        style: {
          color: 'black',
        },
      },
      accessibility: {
        typeDescription: 'Flowchart',
      },
    });
  }

  protected initHerzienAdviesRapporten() {
    const chartContainer = (this.$refs.herzien as any);
    if (! chartContainer) {
      return;
    }

    const router = this.$router;

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        backgroundColor: 'white',
        height: 300,
        events: {
          load() {
            // Draw the flow chart
            const ren = this.renderer;
            const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, - 5];
            const leftArrow = ['M', 100, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, - 5];

            const drawHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, lineType, length, 0, lineType, arrowHook, 5, 'M', length, 0, lineType, arrowHook, - 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawVerticalArrow = function (posX: number, posY: number, length: number, color = 'grey') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, 'L', 0, length, 'L', 5, arrowHook, 'M', 0, length, 'L', - 5, arrowHook])
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawStatusBox = function (posX: number, posY: number, status: string, boxWidth = 100) {
              let label = getStatusLabel(status);
              if (status === 'zienswijze_werkvoorbereiding_rejected') label = 'Herzien rapport <br> Teruggezet SBA';
              if (status === 'zienswijze_veldwerk_rejected') label = 'Herzien rapport Afgekeurd <br> Schadebepaling';

              ren.label(label, posX, posY).attr({
                fill: statusHexColor[getStatusColor(status)],
                'stroke-width': 2,
                padding: 5,
                r: 5,
                width: boxWidth,
              }).css({
                color: 'white',
                cursor: 'pointer',
              }).add()
                .on('click', () => {
                  router.push(`/reports?status=${status}`);
                });
            };

            if (! rightArrow || ! leftArrow) return;

            // Separator, IMG | Bureau
            ren.path(['M', 250, 40, 'L', 250, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Bureau | Schadebepaling & Afhandeling
            ren.path(['M', 790, 40, 'L', 790, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Headers
            ren.label('IMG', 20, 40).add();
            ren.label('Bureau', 270, 40).add();
            ren.label('Schadeafhandeling', 810, 40).add();

            // Verstuurd aan aanvrager
            drawStatusBox(40, 100, 'downloaded', 170);
            drawHorizontalArrow(230, 112, 40); // downloaded --> zienswijze_in_progress

            // Herzien Rapport Teruggezet naar Werkvoorbereiding
            drawStatusBox(40, 220, 'zienswijze_werkvoorbereiding_rejected', 170);
            ren.path([
              'M', 200, 200,
              'C', 200, 200, 210, 150, 270, 130,
              'M', 270, 130,
              'L', 262, 129,
              'M', 270, 130,
              'L', 264, 137,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // zienswijze_werkvoorbereiding_rejected --> zienswijze_in_progress

            // Herzien rapport Lopend
            drawStatusBox(280, 100, 'zienswijze_in_progress', 170);
            drawHorizontalArrow(470, 112, 60); // zienswijze_in_progress --> zienswijze_checking

            // Herzien rapport Afgekeurd
            drawStatusBox(280, 160, 'zienswijze_rejected', 170);
            ren.path([
              'M', 480, 160,
              'C', 480, 160, 510, 170, 540, 130,
              'M', 540, 130,
              'L', 532, 133,
              'M', 540, 130,
              'L', 538, 140,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // zienswijze_rejected --> zienswijze_in_progress

            // Herzien rapport Te controleren
            drawStatusBox(540, 100, 'zienswijze_checking', 220);
            drawHorizontalArrow(780, 112, 30); // zienswijze_checking --> zienswijze_approved
            ren.path([
              'M', 530, 125,
              'C', 530, 125, 500, 120, 475, 151,
              'M', 475, 142,
              'L', 475, 151,
              'M', 474, 151,
              'L', 484, 151,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // zienswijze_checking --> zienswijze_rejected

            // Herzien rapport Afgekeurd schadebepaling
            drawStatusBox(540, 160, 'zienswijze_veldwerk_rejected', 220);
            drawHorizontalArrow(780, 172, 30); // zienswijze_veldwerk_rejected --> zienswijze_corrected
            drawHorizontalArrow(530, 172, - 60, 'red'); // zienswijze_veldwerk_rejected --> zienswijze_rejected

            // Herzien rapport Nieuw Opgeleverd
            drawStatusBox(820, 100, 'zienswijze_approved', 220);
            ren.path([
              'M', 1060, 110,
              'C', 1060, 110, 1100, 160, 1060, 210,
              'M', 1060, 210,
              'L', 1062, 200,
              'M', 1060, 210,
              'L', 1070, 206,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // zienswijze_approved --> zienswijze_closed

            ren.path([
              'M', 812, 130,
              'C', 812, 130, 805, 140, 775, 160,
              'M', 775, 160,
              'L', 777, 150,
              'M', 775, 160,
              'L', 785, 160,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // zienswijze_approved --> zienswijze_veldwerk_rejected

            // Herzien rapport Gecorrigeerd
            drawStatusBox(820, 160, 'zienswijze_corrected', 220);
            drawVerticalArrow(930, 192, 20); // zienswijze_corrected --> zienswijze_closed
            ren.path([
              'M', 810, 190,
              'C', 810, 190, 790, 210, 760, 190,
              'M', 764, 200,
              'L', 760, 190,
              'M', 772, 190,
              'L', 760, 190,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // zienswijze_corrected --> zienswijze_veldwerk_rejected

            // Herzien rapport Afgewikkeld
            drawStatusBox(820, 220, 'zienswijze_closed', 220);
          },
        },
      },
      title: {
        text: 'Nader Advies',
        style: {
          color: 'black',
        },
      },
      accessibility: {
        typeDescription: 'Flowchart',
      },
    });
  }

  protected initBezwaarAdviesRapporten() {
    const chartContainer = (this.$refs.bezwaar as any);
    if (! chartContainer) {
      return;
    }

    const router = this.$router;

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        backgroundColor: 'white',
        height: 400,
        events: {
          load() {
            // Draw the flow chart
            const ren = this.renderer;
            const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, - 5];
            const leftArrow = ['M', 100, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, - 5];

            const drawHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, lineType, length, 0, lineType, arrowHook, 5, 'M', length, 0, lineType, arrowHook, - 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawVerticalArrow = function (posX: number, posY: number, length: number, color = 'grey') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, 'L', 0, length, 'L', 5, arrowHook, 'M', 0, length, 'L', - 5, arrowHook])
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawStatusBox = function (posX: number, posY: number, status: string, boxWidth = 100) {
              let label = getStatusLabel(status);
              if (status === 'objection_werkvoorbereiding_rejected') label = 'Bezwaar Teruggezet <br> BC';

              ren.label(label, posX, posY).attr({
                fill: statusHexColor[getStatusColor(status)],
                'stroke-width': 2,
                padding: 5,
                r: 5,
                width: boxWidth,
              }).css({
                color: 'white',
                cursor: 'pointer',
              }).add()
                .on('click', () => {
                  router.push(`/reports?status=${status}`);
                });
            };

            if (! rightArrow || ! leftArrow) return;

            // Separator, IMG | Bureau
            ren.path(['M', 250, 40, 'L', 250, 400]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Bureau | Schadebepaling & Afhandeling
            ren.path(['M', 790, 40, 'L', 790, 400]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Headers
            ren.label('IMG', 20, 40).add();
            ren.label('Bureau', 270, 40).add();
            ren.label('Bezwaaradviescommissie', 810, 40).add();

            // Bezwaar Werkvoorbereiding
            drawStatusBox(40, 160, 'objection_created', 170);
            ren.path([
              'M', 230, 160,
              'C', 230, 160, 260, 140, 270, 130,
              'M', 270, 130,
              'L', 262, 130,
              'M', 270, 130,
              'L', 268, 140,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // objection_created --> objection_pending_assignment
            drawHorizontalArrow(230, 172, 40); // objection_created --> objection_in_progress

            // Bezwaar Teruggezet naar Werkvoorbereiding
            drawStatusBox(40, 220, 'objection_werkvoorbereiding_rejected', 170);
            ren.path([
              'M', 230, 220,
              'C', 230, 220, 260, 200, 270, 190,
              'M', 270, 190,
              'L', 262, 190,
              'M', 270, 190,
              'L', 268, 198,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // objection_werkvoorbereiding_rejected --> objection_in_progress

            // Bezwaar Afwachting Deskundige
            drawStatusBox(280, 100, 'objection_pending_assignment', 200);
            drawVerticalArrow(385, 130, 20); // objection_pending_assignment --> objection_in_progress

            // Bezwaar Lopend
            drawStatusBox(280, 160, 'objection_in_progress', 170);
            drawHorizontalArrow(470, 172, 60); // objection_in_progress --> objection_checking

            // Bezwaar Afgekeurd
            drawStatusBox(280, 220, 'objection_rejected', 170);
            ren.path([
              'M', 480, 220,
              'C', 480, 220, 510, 220, 540, 190,
              'M', 540, 190,
              'L', 532, 190,
              'M', 540, 190,
              'L', 538, 199,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // objection_rejected --> objection_in_progress
            ren.path([
              'M', 530, 182,
              'C', 530, 182, 500, 185, 475, 210,
              'M', 475, 210,
              'L', 475, 202,
              'M', 474, 210,
              'L', 484, 210,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // objection_checking --> objection_rejected

            // Bezwaar Te controleren
            drawStatusBox(540, 160, 'objection_checking', 220);
            drawHorizontalArrow(780, 172, 30); // objection_checking --> objection_approved

            // Bezwaar Afgekeurd schadebepaling
            drawStatusBox(540, 220, 'objection_veldwerk_rejected', 220);
            drawHorizontalArrow(530, 232, - 60, 'red'); // objection_veldwerk_rejected --> objection_checking
            drawHorizontalArrow(780, 232, 30); // objection_veldwerk_rejected --> objection_corrected

            ren.path([
              'M', 812, 190,
              'C', 812, 190, 805, 200, 777, 220,
              'M', 777, 220,
              'L', 780, 210,
              'M', 777, 220,
              'L', 789, 220,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // objection_approved --> objection_veldwerk_rejected

            // Bezwaar Nieuw Opgeleverd
            drawStatusBox(820, 160, 'objection_approved', 220);
            ren.path([
              'M', 1060, 170,
              'C', 1060, 170, 1100, 210, 1060, 280,
              'M', 1060, 280,
              'L', 1062, 268,
              'M', 1060, 280,
              'L', 1070, 274,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // objection_approved --> objection_closed

            // Bezwaar Gecorrigeerd
            drawStatusBox(820, 220, 'objection_corrected', 220);
            drawVerticalArrow(930, 250, 20); // objection_corrected --> objection_pending_close
            ren.path([
              'M', 810, 255,
              'C', 810, 255, 790, 265, 760, 255,
              'M', 764, 263,
              'L', 760, 255,
              'M', 772, 253,
              'L', 760, 255,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // objection_corrected --> objection_pending_close

            // Bezwaar Afgewikkeld
            drawStatusBox(820, 280, 'objection_pending_close', 220);
            drawVerticalArrow(930, 310, 20); // objection_pending_close --> objection_closed

            // Bezwaar Afgewikkeld
            drawStatusBox(820, 340, 'objection_closed', 220);
          },
        },
      },
      title: {
        text: 'Bezwaar',
        style: {
          color: 'black',
        },
      },
      accessibility: {
        typeDescription: 'Flowchart',
      },
    });
  }

  protected initSmrRapporten() {
    const chartContainer = (this.$refs.stuwmeer as any);
    if (! chartContainer) {
      return;
    }

    const router = this.$router;

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        backgroundColor: 'white',
        height: 330,
        events: {
          load() {
            // Draw the flow chart
            const ren = this.renderer;
            const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, - 5];
            const leftArrow = ['M', 100, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, - 5];

            const drawHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, lineType, length, 0, lineType, arrowHook, 5, 'M', length, 0, lineType, arrowHook, - 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawVerticalArrow = function (posX: number, posY: number, length: number, color = 'grey') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, 'L', 0, length, 'L', 5, arrowHook, 'M', 0, length, 'L', - 5, arrowHook])
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawStatusBox = function (posX: number, posY: number, status: string, boxWidth = 100) {
              let label = getStatusLabel(status);
              if (label === 'Herzien rapport Teruggezet naar Werkvoorbereiding') label = 'Herzien rapport<br/>Teruggezet naar Werkvoorbereiding';

              ren.label(label, posX, posY).attr({
                fill: statusHexColor[getStatusColor(status)],
                'stroke-width': 2,
                padding: 5,
                r: 5,
                width: boxWidth,
              }).css({
                color: 'white',
                cursor: 'pointer',
              }).add()
                .on('click', () => {
                  router.push(`/reports?status=${status}`);
                });
            };

            if (! rightArrow || ! leftArrow) return;

            // Separator, IMG | Bureau
            ren.path(['M', 250, 40, 'L', 250, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Bureau | Schadebepaling & Afhandeling
            ren.path(['M', 790, 40, 'L', 790, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Headers
            ren.label('IMG', 20, 40).add();
            ren.label('Bureau', 270, 40).add();
            ren.label('Schadebepaling & Afhandeling', 810, 40).add();

            // SMR Planning
            drawStatusBox(40, 100, 'smr_created', 170);
            drawHorizontalArrow(230, 112, 40); // smr_created --> smr_in_progress

            // SMR Teruggezet naar Werkvoorbereiding
            drawStatusBox(40, 220, 'smr_werkvoorbereiding_rejected', 170);
            ren.path([
              'M', 200, 200,
              'C', 200, 200, 210, 150, 270, 130,
              'M', 270, 130,
              'L', 262, 129,
              'M', 270, 130,
              'L', 264, 137,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // smr_werkvoorbereiding_rejected --> smr_in_progress

            // SMR Lopend
            drawStatusBox(280, 100, 'smr_in_progress', 170);
            drawHorizontalArrow(470, 112, 60); // smr_in_progress --> smr_checking

            // SMR Afgekeurd
            drawStatusBox(280, 160, 'smr_rejected', 170);
            ren.path([
              'M', 480, 160,
              'C', 480, 160, 510, 170, 540, 130,
              'M', 540, 130,
              'L', 532, 133,
              'M', 540, 130,
              'L', 538, 140,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // smr_rejected --> smr_in_progress

            // SMR Te controleren
            drawStatusBox(540, 100, 'smr_checking', 220);
            drawHorizontalArrow(780, 112, 30); // smr_checking --> smr_approved
            ren.path([
              'M', 530, 125,
              'C', 530, 125, 500, 120, 475, 151,
              'M', 475, 142,
              'L', 475, 151,
              'M', 474, 151,
              'L', 484, 151,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // smr_checking --> smr_rejected

            // SMR Afgekeurd schadebepaling
            drawStatusBox(540, 160, 'smr_veldwerk_rejected', 220);
            drawHorizontalArrow(780, 172, 30); // smr_veldwerk_rejected --> smr_corrected
            drawHorizontalArrow(530, 172, - 60, 'red'); // smr_veldwerk_rejected --> smr_rejected

            // SMR Nieuw Opgeleverd
            drawStatusBox(820, 100, 'smr_approved', 220);
            ren.path([
              'M', 1060, 110,
              'C', 1060, 110, 1100, 160, 1060, 210,
              'M', 1060, 210,
              'L', 1062, 200,
              'M', 1060, 210,
              'L', 1070, 206,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // smr_approved --> smr_closed

            ren.path([
              'M', 812, 130,
              'C', 812, 130, 805, 140, 775, 160,
              'M', 775, 160,
              'L', 777, 150,
              'M', 775, 160,
              'L', 785, 160,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // smr_approved --> smr_veldwerk_rejected

            // SMR Gecorrigeerd
            drawStatusBox(820, 160, 'smr_corrected', 220);
            drawVerticalArrow(930, 192, 20); // smr_corrected --> smr_closed
            ren.path([
              'M', 810, 190,
              'C', 810, 190, 790, 210, 760, 190,
              'M', 764, 200,
              'L', 760, 190,
              'M', 772, 190,
              'L', 760, 190,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // smr_corrected --> smr_veldwerk_rejected

            // SMR Afgewikkeld
            drawStatusBox(820, 220, 'smr_closed', 220);

            // SMR Zonder opname
            drawStatusBox(820, 280, 'smr_no_visit', 220);
          },
        },
      },
      title: {
        text: 'Stuwmeer',
        style: {
          color: 'black',
        },
      },
      accessibility: {
        typeDescription: 'Flowchart',
      },
    });
  }

  protected initVesRapporten() {
    const chartContainer = (this.$refs.ves as any);
    if (! chartContainer) {
      return;
    }

    const router = this.$router;

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        backgroundColor: 'white',
        height: 330,
        events: {
          load() {
            // Draw the flow chart
            const ren = this.renderer;
            const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, - 5];
            const leftArrow = ['M', 100, 0, 'L', 0, 0, 'L', 5, 5, 'M', 0, 0, 'L', 5, - 5];

            const drawHorizontalArrow = function (posX: number, posY: number, length: number, color = 'grey', lineType = 'L') {
              const arrowHook = length < 0 ? (length + 5) : (length - 5);

              ren.path(['M', 0, 0, lineType, length, 0, lineType, arrowHook, 5, 'M', length, 0, lineType, arrowHook, - 5] as SVGPathArray)
                .attr({
                  'stroke-width': 2,
                  stroke: color,
                })
                .translate(posX, posY)
                .add();
            };

            const drawStatusBox = function (posX: number, posY: number, status: string, boxWidth = 100) {
              let label = getStatusLabel(status);
              if (label === 'Herzien rapport Teruggezet naar Werkvoorbereiding') label = 'Herzien rapport<br/>Teruggezet naar Werkvoorbereiding';

              ren.label(label, posX, posY).attr({
                fill: statusHexColor[getStatusColor(status)],
                'stroke-width': 2,
                padding: 5,
                r: 5,
                width: boxWidth,
              }).css({
                color: 'white',
                cursor: 'pointer',
              }).add()
                .on('click', () => {
                  router.push(`/reports?status=${status}&filter=ves_workflow`);
                });
            };

            if (! rightArrow || ! leftArrow) return;

            // Separator, IMG | Bureau
            ren.path(['M', 250, 40, 'L', 250, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            }).add();

            // Separator, Bureau | Schadebepaling & Afhandeling
            ren.path(['M', 790, 40, 'L', 790, 330]).attr({
              'stroke-width': 2,
              stroke: 'silver',
              dashstyle: 'dash',
            })
              .add();

            // Headers
            ren.label('IMG', 20, 40).add();
            ren.label('Bureau', 270, 40).add();
            ren.label('Schadebepaling & Afhandeling', 810, 40).add();

            // Planning
            drawStatusBox(40, 100, ReportStatus.PLANNING, 170);
            drawHorizontalArrow(230, 112, 40);

            // Teruggezet planning
            drawStatusBox(40, 160, ReportStatus.BACK_TO_PLANNING, 170);
            ren.path([
              'M', 270, 125,
              'C', 270, 125, 225, 130, 219, 151,
              'M', 218, 142,
              'L', 218, 151,
              'M', 217, 151,
              'L', 227, 151,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // opname_in_progress --> back_to_planning
            ren.path([
              'M', 225, 160,
              'C', 225, 160, 260, 170, 280, 130,
              'M', 281, 130,
              'L', 273, 133,
              'M', 281, 130,
              'L', 279, 140,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // back_to_planning --> opname_in_progress

            // Opnemer
            drawStatusBox(280, 100, ReportStatus.OPNAME_IN_PROGRESS, 170);
            drawHorizontalArrow(470, 112, 60); // opname_in_progress --> checking

            // Opnemer teruggezet
            drawStatusBox(280, 160, ReportStatus.OPNAME_REJECTED, 170);

            // Te controleren
            drawStatusBox(540, 100, ReportStatus.CHECKING, 220);
            drawHorizontalArrow(780, 112, 30); // checking --> opname_rejected
            ren.path([
              'M', 530, 125,
              'C', 530, 125, 500, 120, 475, 151,
              'M', 475, 142,
              'L', 475, 151,
              'M', 474, 151,
              'L', 484, 151,
            ]).attr({
              'stroke-width': 2,
              stroke: 'red',
            }).add(); // checking --> opname_rejected
            ren.path([
              'M', 480, 160,
              'C', 480, 160, 510, 170, 540, 130,
              'M', 540, 130,
              'L', 532, 133,
              'M', 540, 130,
              'L', 538, 140,
            ]).attr({
              'stroke-width': 2,
              stroke: 'grey',
            }).add(); // opname_rejected --> checking

            // Verstuurd aan aanvrager
            drawStatusBox(820, 100, ReportStatus.DOWNLOADED, 220);
          },
        },
      },
      title: {
        text: 'VES',
        style: {
          color: 'black',
        },
      },
      accessibility: {
        typeDescription: 'Flowchart',
      },
    });
  }
}
