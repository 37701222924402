import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=938e51b6&scoped=true&"
import script from "@/views/Overview/Overview.ts?vue&type=script&lang=ts&"
export * from "@/views/Overview/Overview.ts?vue&type=script&lang=ts&"
import style0 from "@/views/Overview/overview.scss?vue&type=style&index=0&id=938e51b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "938e51b6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('938e51b6')) {
      api.createRecord('938e51b6', component.options)
    } else {
      api.reload('938e51b6', component.options)
    }
    module.hot.accept("./Overview.vue?vue&type=template&id=938e51b6&scoped=true&", function () {
      api.rerender('938e51b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Overview/Overview.vue"
export default component.exports